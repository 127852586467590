/* ---- FONT STYLES ---- */
.homeText {
    font-size: 80px;
    color: white;
    width: 90vw;
    font-weight: 700;
}

.homeTextRedU {
    font-size: 80px;
    color: #F46969;
    width: 90vw;
    text-decoration: underline;
    font-weight: 700;
}
/* ---------------------- */


.contactUsContainer {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 40vh; */
}

.contactUsTextContainer {
    display: flex;
    flex-direction: column;
    background-color: #141414;
    align-items: center;
    /* justify-content: center; */
    /* height: 40vh; */
    width: 94vw;
    border-radius: 30px;
}

/* Styles for screens with a width between 600 and 900 pixels */
@media screen and (max-width: 600px) {
    .homeText {
        font-size: 50px;
        color: white;
        width: 85vw;
        font-weight: 700;
    }
    .homeTextRedU {
        font-size: 50px;
        color: #F46969;
        text-decoration: underline;
        font-weight: 700;
    }
    
}

@media screen and (max-width: 400px) {
    .homeText {
        font-size: 42px;
        color: white;
        width: 85vw;
        font-weight: 700;
    }
    .homeTextRedU {
        font-size: 42px;
        color: #F46969;
        text-decoration: underline;
        font-weight: 700;
    }
    
}
