/* 
    COLORS:
    DARK_GRAY: #0F0F0F
    CORAL_RED: #F46969
    GRAY_BOX: #141414
*/

.background {
    background-color: #0F0F0F;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}


.homeImageContainer {
    display: flex;
    /* min-height: 60vh; */
}

.bgImageHome {
    display: flex;
    position: absolute;
    align-items: baseline;
    width: 100vw;
}

.homeTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 40vh; */
    min-width: 100vw;
}




/* ---- ICONS ---- */
.emailIcon {
    color: white;
    /* size: 20px; */
}

.emailIcon:hover {
    color: #F46969;
}

/* ---- BORDERS ---- */
.redBorder {
    height: 0.3vh;
    background-color: #F46969;
    width: 100vw;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

/* ---- FONT STYLES ---- */
.homeText {
    font-size: 90px;
    color: white;
    font-weight: 700;
}

.homeTextRedU {
    font-size: 90px;
    color: #F46969;
    text-decoration: underline;
    font-weight: 700;
}

@media screen and (max-width: 600px) {
    .homeText {
        font-size: 40px;
        color: white;
        font-weight: 700;
    }
    
    .homeTextRedU {
        font-size: 40px;
        color: #F46969;
        text-decoration: underline;
        font-weight: 700;
    }
    .homeImageContainer {
        justify-content: center;
    }
}
