/* 
    COLORS:
    DARK_GRAY: #0F0F0F
    CORAL_RED: #F46969
    GRAY_BOX: #141414
*/

.topnav {
    display: flex;
    justify-content: center;
    height: 20vh;
    width: 100vw;
    background-color: black;
}

.navButtonContainer {
    display: flex;
}

.navLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarLogo {
    height: 15vh;
}

.navLinksContainer {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 20vh;
    right: 100px;
    column-gap: 2vw;
}

.navbarToggle {
    display: flex;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
    /* width: 25vw; */
    /* height: 20vh; */
    right: 100px;
}

.navbar-toggler{
    color: white !important;
}
.pageLink {
    color: white !important;
    font-size: 14px !important;
    text-decoration: none !important;
}

.pageLink:hover {
    color: #F46969 !important;
    text-decoration: underline !important;
}

.pageLinkPlacement {
    display: flex;
    /* width: 10vw; */
    justify-content: center;
    align-items: center;
}

.pageLinkContainer {
    display: flex;
    /* width: 60vw; */
    column-gap: 20px;
    justify-content: flex-end;
    align-items: center;
}
#basic-navbar-nav {
    display: flex!important;
    flex-basis: auto !important; 
    justify-content: flex-end !important;
}

@media screen and (max-width: 1300px) {
    .navLinksContainer {
        display: none;
    }
  }

@media screen and (min-width: 1300px) {
.navbarToggle {
    display: none;
}
}

@media screen and (max-width: 500px) {
    .navbarLogo {
        height: 10vh;
    }
    .pageLinkPlacement {
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
    }
    .pageLinkContainer {

    }
}
@media screen and (min-width: 576px) {
    
}



