.background {
    display: flex;
    height: 90vh;
    justify-content: center;
    align-items: center;
}

.quoteFormContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    height: 70vh;
    width: 94vw;
    justify-content: center;
    align-items: center;
    background-color: #141414;
    border-radius: 30px;
}

.textArea-0-2-16-rfc-input {
    min-height: 40px;
    font-size: 20px !important;
}

.formControl-0-2-18 .rfc-input {
    font-size: 20px !important;
}

.comment {
    font-size: 16px;
    padding-left: 10px;
    padding-top: 5px;
}

.comment::placeholder {
    font-size: 16px;
    color: #c3d4e4;
}

/* .inputContainer {
    display: flex;
    height: 10vh;
} */

/* ---- FONT STYLES ---- */
.homeText {
    font-size: 100px;
    color: white;
    font-weight: 700;
}

.homeTextRedU {
    font-size: 100px;
    color: #F46969;
    text-decoration: underline;
    font-weight: 700;
}

.subHeading {
    font-size: 40px;
    color: white;
    font-weight: 700;
}

.subHeadingRedU {
    font-size: 40px;
    color: white;
    font-weight: 700;
    color: #F46969;
    text-decoration: underline;
}

.body {
    font-size: 20px;
    color: white;
    font-weight: 400;
}

.bodyRedU{
    font-size: 30px !important;
    /* color: white; */
    font-weight: 400;
    color: #F46969;
    text-decoration: underline;
}

/* ---------------------- */

@media screen and (max-width: 600px) {
    .subHeading {
        font-size: 20px;
        color: white;
        font-weight: 700;
    }
    
    .subHeadingRedU {
        font-size: 20px;
        color: white;
        font-weight: 700;
        color: #F46969;
        text-decoration: underline;
    }
    .quoteFormContainer {
        display: flex;
        flex-direction: column;
        row-gap: 2vh;
        height: 70vh;
        width: 94vw;
        justify-content: center;
        align-items: center;
        background-color: #141414;
        border-radius: 30px;
    }
    
}