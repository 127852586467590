.requestQuoteContainer {
    display: flex;
    height: 60vh;
    width: 100vw;

}

.requestQuote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #141414;
    /* height: 60vh; */
    width: 30vw;
}

.beforeAfter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 60vh;
}

.servicesContainer {
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 60vh;
    justify-content: center;
}

.camryBefore {
    height: 50vh;
    margin-left: 3vw;
}

.camryAfter {
    height: 50vh;
    margin-left: 3vw;
}
.imagesContainer {
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
}
.services {
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
    width: 25vw;
}
/* ---- ICONS ---- */
.emailIcon {
    color: white;
    /* size: 20px; */
}

.emailIcon:hover {
    color: #F46969;
}

/* ---- BORDERS ---- */
.redBorder {
    height: 0.3vh;
    background-color: #F46969;
    width: 100vw;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 600px) {
    .requestQuoteContainer {
        display: flex;
        height: 75vh;
        width: 100vw;
        flex-direction: column;
    }
    .requestQuote {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #141414;
        /* height: 100vh; */
        width: auto;
    }
    .beforeAfter {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 75vh;
        background-color: #141414;
    }

    .imagesContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
    
    .camryBefore {
        height: 20vh;
        /* margin-left: 3vw; */
    }
    
    .camryAfter {
        height: 20vh;
        /* margin-left: 3vw; */
    }
    .servicesContainer {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        justify-content: center;
    }
    .homeText {
        font-size: 70px;
        color: white;
        font-weight: 700;
    }
    
    .homeTextRedU {
        font-size: 70px;
        color: #F46969;
        text-decoration: underline;
        font-weight: 700;
    }
    .services {
        display: flex;
        flex-direction: column;
        /* margin-left: 3vw; */
        width: auto;
    }
    
    
}

/* ---- FONT STYLES ---- */
.homeText {
    font-size: 90px;
    color: white;
    font-weight: 700;
}

.homeTextRedU {
    font-size: 90px;
    color: #F46969;
    text-decoration: underline;
    font-weight: 700;
}
