/* ---- FONT STYLES ---- */
.homeText {
    font-size: 80px;
    color: white;
    font-weight: 700;
}

.homeTextRedU {
    font-size: 80px;
    color: #F46969;
    text-decoration: underline;
    font-weight: 700;
}

.subHeading {
    font-size: 2vw;
    color: white;
    font-weight: 500;
}

.subHeadingRedU {
    font-size: 2vw;
    color: white;
    font-weight: 500;
    color: #F46969;
    text-decoration: underline;
}

.body {
    font-size: 0.5vw;
    color: white;
    font-weight: 400;
}

.bodyRedU{
    font-size: 0.5vw;
    color: white;
    font-weight: 400;
    color: #F46969;
    text-decoration: underline;
}
/* ---------------------- */


.ourMissionContainer {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 42vh; */
}

.ourMissionTextContainer {
    display: flex;
    flex-direction: column;
    background-color: #141414;
    align-items: center;
    /* justify-content: center; */
    /* height: 37vh; */
    width: 94vw;
    border-radius: 30px;
}

@media screen and (max-width: 600px) {
    .homeText {
        font-size: 42px;
        color: white;
        font-weight: 700;
        width: 90vw;
    }
    
    .homeTextRedU {
        font-size: 42px;
        color: #F46969;
        text-decoration: underline;
        font-weight: 700;
        width: 90vw;
    }
}